import React from "react";
import { LoadingLine } from "@thecb/components";
import { BLACK_SQUEEZE, GRECIAN_GREY } from "../../constants/colors";

const ContentWithLoader = ({
  content,
  isLoading,
  displayContent = true,
  failedToLoad = false,
  minWidth = "70",
  maxWidth = "218",
  height = "18px",
  margin = "0px"
}) => {
  const extraStyles = failedToLoad
    ? {
        borderRadius: "0.125rem",
        animation: "none",
        backgroundImage: "none",
        backgroundColor: GRECIAN_GREY
      }
    : { borderRadius: "0.125rem" };

  if (isLoading || failedToLoad) {
    return (
      <LoadingLine
        minWidth={minWidth}
        maxWidth={maxWidth}
        height={height}
        margin={margin}
        backgroundColor={GRECIAN_GREY}
        foregroundColor={BLACK_SQUEEZE}
        style={extraStyles}
      />
    );
  }
  return !content || !displayContent ? null : <>{content}</>;
};

export default ContentWithLoader;
