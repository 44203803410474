/**
 * Welcome to The General (tm) for utils that are truly one off.
 * Our goal is too keep this file as small as possible. If you're
 * about to add something here ask yourself: am I sure this can't
 * go in a util file with other things similar to it?
 */

import * as R from "ramda";
import S from "sanctuary";
import numeral from "numeral";
import { URL_TEST } from "../constants/regex_constants";
import {
  PARTS_OF_SPEECH,
  STATE_ABBREVIATIONS
} from "../constants/formatting_constants";

export const toMaybe = x => (x ? S.Just(x) : S.Nothing);

export const toEither = _default =>
  S.compose(S.maybeToEither(_default))(toMaybe);

export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

const deleteChars = cs => s => {
  if (!Array.isArray(cs) || cs.length === 0) {
    return s; // No characters to delete
  }
  if (typeof s !== "string") {
    return "";
  }
  const charToDelete = R.head(cs);
  const replacedS = charToDelete ? s.replace(charToDelete, "") : "";
  return cs.length
    ? deleteChars(replacedS.includes(charToDelete) ? cs : R.tail(cs))(replacedS)
    : s;
};

export const convertMoneyDecimalToCents = n => Math.round(n * 100);
export const moneyStringToFloat = s => parseFloat(deleteChars(["$", ","])(s));
export const formatMoneyString = s => numeral(s).format("$0,0.00");
export const convertCentsToMoneyDecimal = n => (n / 100).toFixed(2);
export const convertCentsToMoneyInt = n => (n / 100).toFixed(0);

export const storeCurrency = R.pipe(
  moneyStringToFloat,
  convertMoneyDecimalToCents
);

export const roundStringToTwoDecimals = R.pipe(moneyStringToFloat, x =>
  x.toFixed(2)
);

export const arrayify = R.cond([
  [R.is(Array), R.identity],
  [R.is(Object), R.pipe(R.toPairs, R.map(R.last))],
  [R.T, R.append(R.__, [])]
]);

export const isNotEmpty = R.complement(R.isEmpty);

export const safeChildren = (children, replacement = []) => {
  if (R.is(Array, children)) {
    return children.map(child => (!child ? replacement : child));
  }
  return !children ? replacement : children;
};

export const evolveCents = cents =>
  formatMoneyString(convertCentsToMoneyDecimal(cents));

export const normalizeById = S.reduce(sofar => curr => ({
  ...sofar,
  [curr.id]: curr
}))({});

/**
 * buildObligationValues takes a line item that comes from the config file, and obligationPath
 * (usually the custom attributes on an obligation, and will first check to see if it is an array with a leading and
 * trailing %. If it has that, it will use the line item and obligationPath to get the correct value from the
 * custom attributes. if it is a string or a number (title or amount) it will just return the value.
 */
export const buildObligationValues = (lineItem = "", obligationPath) =>
  !Array.isArray(lineItem)
    ? obligationPath?.[lineItem] ?? lineItem
    : lineItem
        .map(value => {
          const matchRegex = /^%(.*%)?$/gim;
          if (matchRegex.test(value)) {
            const formattedValue = value.replace(/%/g, "");
            const obligationValue = obligationPath?.[formattedValue] ?? "";

            return obligationValue;
          }
          return value;
        })
        .join(" ");

/**
 * attachConfigValues takes the config from an account type, and uses buildObligationValues
 * to return a new obligation object with the custom config value's attached to the new obligation object.
 */
export const attachConfigValues = (config, obligation) => {
  const { customAttributes } = obligation;
  // all desc/subdesc values should be provided on obligation config
  // but we use [] as default value while config file is loading from FCS
  // this prevents error if config takes a long time to retrieve, is completely absent, or is incomplete
  const defaultDescriptionObj = {
    description: [],
    subDescription: []
  };
  const { description = [], subDescription = [] } =
    config ?? defaultDescriptionObj;
  const {
    description: detailsDescription = [],
    subDescription: detailsSubDescription = []
  } = config?.details ?? defaultDescriptionObj;
  const {
    description: paymentDescription = [],
    subDescription: paymentSubDescription = []
  } = config?.paymentAttributes ?? defaultDescriptionObj;
  return {
    ...obligation,
    description: buildObligationValues(description, customAttributes),
    subDescription: buildObligationValues(subDescription, customAttributes),
    amountValue: obligation.amountDue,
    details: {
      description: buildObligationValues(detailsDescription, customAttributes),
      subDescription: buildObligationValues(
        detailsSubDescription,
        customAttributes
      )
    },
    paymentAttributes: {
      description: buildObligationValues(paymentDescription, customAttributes),
      subDescription: buildObligationValues(
        paymentSubDescription,
        customAttributes
      ),
      amount: obligation.amountDue
    }
  };
};

export const isAutoPayEnabled = (existingAutoPayData, obligationAssocId) =>
  Object.keys(existingAutoPayData).some(
    autoPayId => autoPayId === obligationAssocId
  );

export const retrieveAutoPayData = (existingAutoPayData, obligationAssocId) =>
  Object.entries(existingAutoPayData).reduce(
    (acc, [id, data]) => (id === obligationAssocId ? { ...data } : acc),
    {}
  );

// These functions help preserve the RemoteData object prototype, so we can use isLoading, isSuccess, isFailure, and notAsked on regular redux objects. They will be put on the object prototype.
export const getPrototypeOf = object => Object.getPrototypeOf(object);
export const attachPrototypeToPayload = payload =>
  Object.entries(payload).reduce((acc, [key, val]) => {
    return { ...acc, [key]: { value: val, enumerable: true } };
  }, {});

export const cleanSubClientName = (client, subClient) =>
  subClient.replace(`${client}-`, "");

export const capitalize = word =>
  word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : word;

export const formatName = name =>
  name
    ? name
        .split("-")
        .map((word, index) => {
          if (PARTS_OF_SPEECH.includes(word) && index !== 0) {
            return word.toLowerCase();
          } else if (STATE_ABBREVIATIONS.some(s => s == word.toUpperCase())) {
            return word.toUpperCase();
          } else {
            return capitalize(word);
          }
        })
        .join(" ")
    : name;

/**
 * Move an element in an array to a desired index.
 * @param array The array
 * @param fromIndex The current index of the element to be moved
 * @param toIndex The index that the element will be moved to
 **/
export const moveTo = (array, fromIndex, toIndex) => {
  let newArray = [...array];
  newArray.splice(toIndex, 0, newArray.splice(fromIndex, 1)[0]);
  return newArray;
};

/**
 *
 * @param total The total number of entities found
 * @param toIndex The number of entities for a single page
 * @returns The total number of pages
 **/
export const getPageCount = (total, perPage) => {
  return Math.ceil(total / perPage);
};

/**
 *
 * @param paramName The name of the query param
 * @returns The query param's value
 */
export const getQueryParam = paramName => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(paramName);
};

/**
 * Coerces input into a lowercase string. This is useful for value comparisons.
 * @param {*} [input=""] - any input
 * @returns {string} a lowercase string
 */
export const coerceToLowercase = (input = "") => String(input).toLowerCase();

/**
 * Coerces input into an uppercase string. This is useful for value comparisons.
 * @param {*} [input=""] - any input
 * @returns {string} an uppercase string
 */
export const coerceToUppercase = (input = "") => String(input).toUpperCase();

/**
 *
 * @param href The link string to check
 * @returns true if the href is external and false if it is an link internal to the site.
 */
export const isExternalLink = href => R.test(URL_TEST, href);

/**
 * Transforms an object into an array of objects with 'key' and 'value' properties.
 * e.g. {foo: "bar", bar: "foo"} => [{key: "foo", value: "bar"}, {key: "bar", value: "foo"}]
 * @param object The object to transform
 * @returns An array of objects with 'key' and 'value' properties
 */
export const objectToArrayOfKeyValues = object =>
  Object.keys(object).map(key => ({ key: key, value: object[key] }));

/**
 * Transforms a kebab-case-string into a snake_case_string
 * @param kebabString
 * @returns A string formatted into snake_case
 */
export const kebabCaseToSnakeCase = kebabString =>
  kebabString?.replace(/-/g, "_")?.toLowerCase();

/**
 * Pluralizes a string based on an item count
 * @param string
 * @param itemCount
 * @returns original string if item count is 1 and the pluralized string if the item count is greater than 1.
 */
export const pluralize = (string, itemCount) =>
  itemCount === 1 ? string : string + "s";
