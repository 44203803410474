/*
 How is this different from thecb/components CollapsibleSection?
  - updated imports to work within the app's src environment
  - the default title line (ie. title/custom title with toggle icon) is extracted to separate component 
    DefaultStyleSectionTitleLine. Apologies for the long name and prop drilling! 
    (can probably be cleaned up a little)
  - new optional prop customSectionTitleComponet (again, apologies for the naming). If not provided, the
    DefaultStyleSectionTitleLine is rendered. Otherwise, a component should be provided. This component
    should handle calling toggleSection
**/
import React, { Fragment } from "react";
import { AnimatePresence } from "framer-motion";
import { themeComponent } from "../../util/themeUtils";

import { constants, Box, Stack, Motion, Title } from "@thecb/components";
import DefaultStyleSectionTitleLine from "./DefaultStyleSectionTitleLine";

export const COLLAPSIBLE_Z_INDEX = 25;

const titleColor = "#292A33";
const headingBackgroundColor = "transparent";
const bodyBackgroundColor = "transparent";

const fallbackValues = {
  titleColor,
  headingBackgroundColor,
  bodyBackgroundColor
};

export const CollapsibleSectionOpenCloseStyle = {
  Chevron: "Chevron",
  PlusMinus: "PlusMinus"
};

const CollapsibleSection = ({
  isOpen,
  toggleSection,
  themeValues,
  isMobile,
  supportsTouch,
  title,
  customPadding,
  initiallyOpen = true,
  openHeight = "auto",
  children,
  customTitle = false,
  stackTitle = false,
  stackTitleContent,
  sectionGap = "0.5rem",
  name = "",
  index = 1,
  customSectionTitleComponent = null,
  openCloseStyle = CollapsibleSectionOpenCloseStyle.Chevron
}) => {
  const numChildren = typeof children === "Array" ? children.length : 1;
  const label =
    name !== "" ? name : !customTitle ? title : "collapsible section";
  const id = `${label?.replaceAll?.(" ", "-")?.toLowerCase()}-${index}`;

  const wrapper = {
    open: {
      height: openHeight,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: 0.15
      }
    },
    closed: {
      height: 0,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: 0.15,
        staggerDirection: -1,
        delay: numChildren * 0.35
      }
    }
  };

  return (
    <Motion
      padding="0"
      hoverStyles={`outline: none;`}
      animate={isOpen ? "open" : "closed"}
      positionTransition
    >
      {stackTitle && <Fragment>{stackTitleContent}</Fragment>}
      <Stack childGap={isOpen && !isMobile ? sectionGap : "0rem"}>
        <Box padding="0" role="heading" aria-label={label} aria-level={3}>
          {customSectionTitleComponent || (
            <DefaultStyleSectionTitleLine
              customPadding={customPadding}
              themeValues={themeValues}
              isMobile={isMobile}
              supportsTouch={supportsTouch}
              toggleSection={toggleSection}
              isOpen={isOpen}
              id={id}
              openCloseStyle={openCloseStyle}
              titleComponent={
                customTitle ? (
                  <Box width="calc(100% - 36px)" padding="0px">
                    {title}
                  </Box>
                ) : (
                  <Title
                    weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                    color={themeValues.titleColor}
                    as="h6"
                    variant="small"
                  >
                    {title}
                  </Title>
                )
              }
            />
          )}
        </Box>
        <AnimatePresence initial={false}>
          {isOpen && (
            <Motion
              padding="0"
              background={themeValues.bodyBackgroundColor}
              key="content"
              positionTransition
              initial={initiallyOpen ? "open" : "closed"}
              exit="closed"
              variants={wrapper}
              extraStyles={`transform-origin: 100% 0; overflow-y: hidden; margin-top: 0;`}
              id={`${id}-content`}
              role={"region"}
              aria-labelledby={`${id}-button`}
            >
              {children}
            </Motion>
          )}
        </AnimatePresence>
      </Stack>
    </Motion>
  );
};

export default themeComponent(
  CollapsibleSection,
  "CollapsibleSection",
  fallbackValues
);
