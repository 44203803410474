import React, { useContext } from "react";
import { Box, Stack } from "../../atoms/layouts";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./LinkCard.theme";
import { ThemeContext } from "styled-components";
import * as Styled from "./LinkCard.styled";
import { noop } from "../../../util/general";

const LinkCard = ({
  title = "Test Workflow",
  subtitle = "Link your benefit plan",
  showLeft,
  leftContent,
  showRight,
  rightContent,
  onClick,
  extraStyles = "",
  extraHoverStyles = "",
  extraActiveStyles = "",
  themeValues,
  titleVariant = "h3",
  disabled = false
}) => {
  const { isMobile } = useContext(ThemeContext);
  const regex = /\W/g;
  const locatorSlug = title?.toLowerCase?.()?.replaceAll?.(regex, "-");

  return (
    <Styled.Container
      borderRadius="8px"
      dataQa={`link-card-${locatorSlug}`}
      width="100%"
      maxWidth={isMobile ? "100%" : "288px"}
      minWidth={isMobile ? "240px" : "288px"}
      minHeight="141px"
      padding="24px"
      theme={themeValues}
      extraStyles={extraStyles}
      hoverStyles={extraHoverStyles}
      activeStyles={extraActiveStyles}
      onClick={disabled ? noop : onClick}
      aria-disabled={disabled}
      isDisabled={disabled}
      role="group"
      aria-label={`${title}, ${subtitle}`}
    >
      <Stack
        childGap={0}
        bottomItem={3}
        justify="space-between"
        style={{ width: "100%" }}
        fullHeight
      >
        <Box padding={0} width="100%">
          <Styled.Title
            variant={titleVariant}
            theme={themeValues}
            margin={0}
            isDisabled={disabled}
          >
            {title}
          </Styled.Title>
        </Box>
        <Box padding={"0 0 40px"} width="100%">
          <Styled.Subtitle
            variant="pS"
            theme={themeValues}
            isDisabled={disabled}
          >
            {subtitle}
          </Styled.Subtitle>
        </Box>
        <Box
          background="transparent"
          borderWidthOverride="0 0 0 0"
          padding="0"
          width="100%"
        >
          <Styled.Footer direction="row" childGap="6px" justify="space-between">
            {/* To keep rightContent aligned right, use an empty Box as leftContent if none is provided */}
            {showLeft && !!leftContent ? (
              leftContent
            ) : (
              <Box extraStyles="margin-right: auto;" />
            )}
            {showRight && !!rightContent && rightContent}
          </Styled.Footer>
        </Box>
      </Stack>
    </Styled.Container>
  );
};

export default themeComponent(LinkCard, "LinkCard", fallbackValues, "primary");
