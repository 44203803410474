import React from "react";
import ProfileAccounts from "./sub-pages/profile-accounts/Accounts";
import ProfileSettings from "./sub-pages/profile-settings/ProfileSettings";
import ProfileWalletSettings from "./sub-pages/profile-wallet-settings/ProfileWalletSettings";
import AddAddress from "./sub-pages/profile-add-address/AddAddress";
import AddEmail from "./sub-pages/profile-add-email/AddEmail";
import AddPhone from "./sub-pages/profile-add-phone/AddPhone";
import AddPayment from "./sub-pages/profile-add-payment/AddPayment";
import AccountDetails from "./sub-pages/profile-account-details/AccountDetails";
import ProfileFindAccount from "./sub-pages/profile-find-account/FindAccount";

import EditName from "./sub-pages/profile-edit-name/EditName";
import ChangePassword from "./sub-pages/profile-change-password/ChangePassword";
import SetUpAutopay from "./sub-pages/profile-set-up-autopay/SetUpAutopay";
import ProfileProperties from "./sub-pages/profile-properties/Properties";
import Support from "./sub-pages/profile-support/Support";
import PaymentPlan from "./sub-pages/profile-payment-plan/PaymentPlan";
import Welcome from "./sub-pages/profile-welcome/Welcome";
import TransactionHistory from "./sub-pages/profile-transaction-history/TransactionHistory";
import TransactionDetails from "./sub-pages/profile-transaction-details/TransactionDetails";
import AccountDetailsV2 from "./sub-pages/profile-account-details/AccountDetailsV2";

const PROFILE_ADD_ADDRESS = "/add-address";
const PROFILE_EDIT_ADDRESS = "/edit-address/:id";
const PROFILE_ADD_EMAIL = "/add-email";
const PROFILE_EDIT_EMAIL = "/edit-email/:id";
const PROFILE_ADD_PAYMENT = "/add-payment";
const PROFILE_EDIT_PAYMENT = "/edit-payment/:id";
const PROFILE_ADD_PHONE = "/add-phone";
const PROFILE_EDIT_PHONE = "/edit-phone/:id";
const PROFILE_CHANGE_PASSWORD = "/change-password";
const PROFILE_EDIT_NAME = "/edit-name";
const PROFILE_FIND_ACCOUNT = "FIND";
const PROFILE_DETAILS = "/details/:id";
const PROFILE_AUTOPAY = "/set-up-autopay";
const PROFILE_PAYMENT_PLAN = "/payment-plan";
const PROFILE_TRANSACTION_DETAILS = "/transaction/:id";
const PROFILE_SETTINGS_INDEX = "SETTINGS";
const PROFILE_WALLET_SETTINGS_INDEX = "WALLET";
const PROFILE_ACCOUNTS_INDEX = "ACCOUNTS";
const PROFILE_PROPERTIES_INDEX = "PROPERTIES";
const PROFILE_SUPPORT_INDEX = "SUPPORT";
const PROFILE_WELCOME_INDEX = "WELCOME";
const PROFILE_TRANSACTION_HISTORY = "HISTORY";

export const routes = {
  [PROFILE_ADD_ADDRESS]: props => <AddAddress {...props} />,
  [PROFILE_EDIT_ADDRESS]: props => <AddAddress {...props} />,
  [PROFILE_ADD_EMAIL]: props => <AddEmail {...props} />,
  [PROFILE_EDIT_EMAIL]: props => <AddEmail {...props} />,
  [PROFILE_ADD_PHONE]: props => <AddPhone {...props} />,
  [PROFILE_EDIT_PHONE]: props => <AddPhone {...props} />,
  [PROFILE_ADD_PAYMENT]: props => <AddPayment {...props} />,
  [PROFILE_EDIT_PAYMENT]: props => <AddPayment {...props} />,
  [PROFILE_EDIT_NAME]: props => <EditName {...props} />,
  [PROFILE_CHANGE_PASSWORD]: props => <ChangePassword {...props} />,
  [PROFILE_DETAILS]: props => {
    return props.accountLoadingExperienceEnabled ? (
      <AccountDetailsV2 {...props} />
    ) : (
      <AccountDetails {...props} />
    );
  },
  [PROFILE_AUTOPAY]: props => <SetUpAutopay {...props} />,
  [PROFILE_PAYMENT_PLAN]: props => <PaymentPlan {...props} />,
  [PROFILE_FIND_ACCOUNT]: props => <ProfileFindAccount {...props} />,
  [PROFILE_SETTINGS_INDEX]: props => <ProfileSettings {...props} />,
  [PROFILE_WALLET_SETTINGS_INDEX]: props => (
    <ProfileWalletSettings {...props} />
  ),
  [PROFILE_ACCOUNTS_INDEX]: props => <ProfileAccounts {...props} />,
  [PROFILE_PROPERTIES_INDEX]: props => <ProfileProperties {...props} />,
  [PROFILE_SUPPORT_INDEX]: props => <Support {...props} />,
  [PROFILE_WELCOME_INDEX]: props => <Welcome {...props} />,
  [PROFILE_TRANSACTION_HISTORY]: props => <TransactionHistory {...props} />,
  [PROFILE_TRANSACTION_DETAILS]: props => <TransactionDetails {...props} />
};
