import styled, { keyframes } from "styled-components";
import { colors } from "../../../constants";

const shineFrames = keyframes`{
  0 {
    background-position: 0 0;
  }
  20% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 100% 100%;
  }
}`;

export const LoadingPill = styled.span`
  display: block;
  width: ${({ width }) => width}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  border-radius: 16px;
  background-image: linear-gradient(
    135deg,
    ${({ backgroundColor }) => backgroundColor} 0%,
    ${({ foregroundColor }) => foregroundColor} 40%,
    ${({ backgroundColor }) => backgroundColor} 60%,
    ${({ backgroundColor }) => backgroundColor} 100%
  );
  background-size: 400%;
  animation: ${shineFrames} 2s infinite ease-in-out;
  animation-direction: reverse;
`;
