import { colors, styleConstants } from "../../../constants";
import { adjustHexColor } from "../../../util/general";

const {
  WHITE,
  TRANSPARENT,
  SAPPHIRE_BLUE,
  PEACOCK_BLUE,
  MANATEE_GREY,
  MATISSE_BLUE,
  HINT_GREEN,
  SEA_GREEN,
  RASPBERRY,
  ERROR_COLOR
} = colors;

const { LINK_TEXT_DECORATION } = styleConstants;

const disabledBorderColor = {
  primary: MANATEE_GREY,
  secondary: MANATEE_GREY,
  back: TRANSPARENT,
  smallPrimary: MANATEE_GREY,
  smallSecondary: MANATEE_GREY,
  smallGhost: TRANSPARENT,
  ghost: TRANSPARENT,
  tertiary: TRANSPARENT,
  danger: MANATEE_GREY,
  dangerSecondary: MANATEE_GREY,
  whitePrimary: MANATEE_GREY,
  whiteSecondary: MANATEE_GREY,
  greenPrimary: SEA_GREEN,
  greenSecondary: SEA_GREEN
};

const disabledColor = {
  primary: WHITE,
  secondary: MANATEE_GREY,
  back: MANATEE_GREY,
  smallPrimary: WHITE,
  smallSecondary: MANATEE_GREY,
  smallGhost: MANATEE_GREY,
  ghost: MANATEE_GREY,
  tertiary: MANATEE_GREY,
  danger: WHITE,
  dangerSecondary: MANATEE_GREY,
  whitePrimary: MANATEE_GREY,
  whiteSecondary: MANATEE_GREY,
  greenPrimary: WHITE,
  greenSecondary: SEA_GREEN
};

const disabledBackgroundColor = {
  primary: MANATEE_GREY,
  secondary: TRANSPARENT,
  back: TRANSPARENT,
  smallPrimary: MANATEE_GREY,
  smallSecondary: TRANSPARENT,
  smallGhost: TRANSPARENT,
  ghost: TRANSPARENT,
  tertiary: TRANSPARENT,
  danger: MANATEE_GREY,
  dangerSecondary: TRANSPARENT,
  whitePrimary: TRANSPARENT,
  whiteSecondary: TRANSPARENT,
  greenPrimary: SEA_GREEN,
  greenSecondary: TRANSPARENT
};

const padding = {
  primary: "0.75rem 1.5rem",
  secondary: "0.75rem 1.5rem",
  back: "0.75rem 1.5rem",
  smallPrimary: "0.75rem 1rem",
  smallSecondary: "0.75rem 1rem",
  smallGhost: "0",
  tertiary: "0.75rem 1.5rem",
  ghost: "0.65rem 0",
  danger: "0.75rem 1.5rem",
  dangerSecondary: "0.75rem 1.5rem",
  whitePrimary: "1.125rem 0.75rem",
  whiteSecondary: "0.75rem 2rem",
  greenPrimary: "0.75rem 1.5rem",
  greenSecondary: "0.75rem 1.5rem"
};

const color = {
  primary: WHITE,
  secondary: MATISSE_BLUE,
  back: MATISSE_BLUE,
  smallPrimary: WHITE,
  smallSecondary: MATISSE_BLUE,
  smallGhost: MATISSE_BLUE,
  ghost: MATISSE_BLUE,
  tertiary: MATISSE_BLUE,
  danger: WHITE,
  dangerSecondary: ERROR_COLOR,
  whitePrimary: WHITE,
  whiteSecondary: WHITE,
  greenPrimary: WHITE,
  greenSecondary: SEA_GREEN
};

const fontSizeVariant = {
  primary: "pS",
  secondary: "pS",
  back: "pS",
  smallPrimary: "pS",
  smallSecondary: "pS",
  smallGhost: "p",
  ghost: "pL",
  tertiary: "pS",
  danger: "pS",
  dangerSecondary: "pS",
  whiteSecondary: "pS",
  whitePrimary: "pS",
  greenPrimary: "pS",
  greenSecondary: "pS"
};

const fontWeight = {
  primary: "600",
  secondary: "600",
  back: "600",
  smallPrimary: "600",
  smallSecondary: "600",
  smallGhost: "400",
  ghost: "600",
  tertiary: "600",
  danger: "600",
  dangerSecondary: "600",
  whiteSecondary: "600",
  whitePrimary: "600",
  greenPrimary: "600",
  greenSecondary: "600"
};

const height = {
  primary: "3rem",
  secondary: "3rem",
  back: "3rem",
  smallPrimary: "2.5rem",
  smallSecondary: "2.5rem",
  smallGhost: "1.5rem",
  ghost: "3rem",
  tertiary: "3rem",
  danger: "3rem",
  dangerSecondary: "3rem",
  whitePrimary: "auto",
  whiteSecondary: "3rem",
  greenPrimary: "3rem",
  greenSecondary: "3rem"
};

const minWidth = {
  primary: "130px",
  secondary: "130px",
  back: "130px",
  smallPrimary: "100px",
  smallSecondary: "100px",
  smallGhost: "100px",
  ghost: "130px",
  tertiary: "130px",
  danger: "130px",
  dangerSecondary: "157px",
  whitePrimary: "130px",
  whiteSecondary: "160px",
  greenPrimary: "130px",
  greenSecondary: "130px"
};

const textDecoration = {
  primary: "none",
  secondary: "none",
  back: "none",
  smallPrimary: "none",
  smallSecondary: "none",
  smallGhost: LINK_TEXT_DECORATION,
  ghost: LINK_TEXT_DECORATION,
  tertiary: "none",
  danger: "none",
  dangerSecondary: "none",
  whitePrimary: "none",
  whiteSecondary: "none",
  greenPrimary: "none",
  greenSecondary: "none"
};

const backgroundColor = {
  primary: MATISSE_BLUE,
  secondary: TRANSPARENT,
  back: TRANSPARENT,
  smallPrimary: MATISSE_BLUE,
  smallSecondary: TRANSPARENT,
  smallGhost: TRANSPARENT,
  ghost: TRANSPARENT,
  tertiary: TRANSPARENT,
  danger: RASPBERRY,
  dangerSecondary: TRANSPARENT,
  whitePrimary: TRANSPARENT,
  whiteSecondary: TRANSPARENT,
  greenPrimary: SEA_GREEN,
  greenSecondary: TRANSPARENT
};

const border = {
  primary: "2px solid " + MATISSE_BLUE,
  secondary: "2px solid " + MATISSE_BLUE,
  back: "2px solid " + MATISSE_BLUE,
  smallPrimary: "2px solid " + MATISSE_BLUE,
  smallSecondary: "2px solid " + MATISSE_BLUE,
  smallGhost: "none",
  ghost: "none",
  tertiary: "none",
  danger: "2px solid " + RASPBERRY,
  dangerSecondary: "2px solid " + ERROR_COLOR,
  whitePrimary: "2px solid " + TRANSPARENT,
  whiteSecondary: "2px solid " + WHITE,
  greenPrimary: "2px solid " + SEA_GREEN,
  greenSecondary: "2px solid " + SEA_GREEN
};

const hoverBackgroundColor = {
  primary: SAPPHIRE_BLUE,
  secondary: "#DBEAF0",
  back: TRANSPARENT,
  smallPrimary: SAPPHIRE_BLUE,
  smallSecondary: "#DBEAF0",
  smallGhost: TRANSPARENT,
  ghost: TRANSPARENT,
  tertiary: TRANSPARENT,
  danger: "#BA002C",
  dangerSecondary: "#FAE7EE",
  whitePrimary: TRANSPARENT,
  whiteSecondary: TRANSPARENT,
  greenPrimary: adjustHexColor(SEA_GREEN, 10, "darken"),
  greenSecondary: HINT_GREEN
};

const hoverBorderColor = {
  primary: SAPPHIRE_BLUE,
  secondary: MATISSE_BLUE,
  back: "#DCEAF1",
  smallPrimary: SAPPHIRE_BLUE,
  smallSecondary: MATISSE_BLUE,
  smallGhost: TRANSPARENT,
  ghost: TRANSPARENT,
  tertiary: TRANSPARENT,
  danger: "#BA002C",
  dangerSecondary: "#B10541",
  whitePrimary: "2px solid " + TRANSPARENT,
  whiteSecondary: "2px solid " + TRANSPARENT,
  greenPrimary: SEA_GREEN,
  greenSecondary: SEA_GREEN
};

const hoverColor = {
  primary: WHITE,
  secondary: SAPPHIRE_BLUE,
  back: SAPPHIRE_BLUE,
  smallPrimary: WHITE,
  smallSecondary: SAPPHIRE_BLUE,
  smallGhost: SAPPHIRE_BLUE,
  ghost: SAPPHIRE_BLUE,
  tertiary: SAPPHIRE_BLUE,
  danger: WHITE,
  dangerSecondary: "#B10541",
  whitePrimary: WHITE,
  whiteSecondary: WHITE,
  greenPrimary: WHITE,
  greenSecondary: SEA_GREEN
};

const activeBackgroundColor = {
  primary: PEACOCK_BLUE,
  secondary: "#B8D5E1",
  back: TRANSPARENT,
  smallPrimary: PEACOCK_BLUE,
  smallSecondary: "#B8D5E1",
  smallGhost: TRANSPARENT,
  ghost: TRANSPARENT,
  tertiary: TRANSPARENT,
  danger: "#870000",
  dangerSecondary: "#FAE7EE",
  whitePrimary: TRANSPARENT,
  whiteSecondary: TRANSPARENT,
  greenPrimary: adjustHexColor(SEA_GREEN, 20, "darken"),
  greenSecondary: adjustHexColor(HINT_GREEN, 5, "darken")
};

const activeBorderColor = {
  primary: PEACOCK_BLUE,
  secondary: MATISSE_BLUE,
  back: PEACOCK_BLUE,
  smallPrimary: PEACOCK_BLUE,
  smallSecondary: MATISSE_BLUE,
  smallGhost: TRANSPARENT,
  ghost: TRANSPARENT,
  tertiary: TRANSPARENT,
  danger: "#870000",
  dangerSecondary: "#910029",
  whitePrimary: "2px solid " + TRANSPARENT,
  whiteSecondary: "2px solid " + TRANSPARENT,
  greenPrimary: SEA_GREEN,
  greenSecondary: SEA_GREEN
};

const activeColor = {
  primary: WHITE,
  secondary: MATISSE_BLUE,
  back: PEACOCK_BLUE,
  smallPrimary: WHITE,
  smallSecondary: PEACOCK_BLUE,
  smallGhost: PEACOCK_BLUE,
  ghost: PEACOCK_BLUE,
  tertiary: PEACOCK_BLUE,
  danger: WHITE,
  dangerSecondary: "#910029",
  whitePrimary: WHITE,
  whiteSecondary: WHITE,
  greenPrimary: WHITE,
  greenSecondary: SEA_GREEN
};

export const fallbackValues = {
  padding,
  color,
  fontSizeVariant,
  fontWeight,
  height,
  minWidth,
  textDecoration,
  backgroundColor,
  border,
  hoverBackgroundColor,
  hoverBorderColor,
  hoverColor,
  activeBackgroundColor,
  activeBorderColor,
  activeColor,
  disabledColor,
  disabledBackgroundColor,
  disabledBorderColor
};
