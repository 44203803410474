import React from "react";
import { LoadingPill } from "./LoadingPill.styled";
import { colors } from "../../../constants";

const LoadingLine = ({
  minWidth,
  maxWidth,
  exactWidth = null,
  height = "16px",
  margin = "0px",
  backgroundColor = colors.SEASHELL_WHITE,
  foregroundColor = colors.ALABASTER_WHITE,
  ...props
}) => {
  const calculatedWidth = Math.floor(
    Math.random() * (parseInt(maxWidth) - parseInt(minWidth) + 1) +
      parseInt(minWidth)
  );

  return (
    <LoadingPill
      aria-label="loading"
      margin={margin}
      height={height}
      width={exactWidth || calculatedWidth}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      {...props}
    />
  );
};

export default LoadingLine;
