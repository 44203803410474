import React, { useState } from "react";
import AmountCallout from "../../../atoms/amount-callout";
import { Box, Stack } from "../../../atoms/layouts";
import Text from "../../../atoms/text";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import { displayCurrency, noop } from "../../../../util/general";
import AutopayModalModule from "./AutopayModalModule";
import LoadingLine from "../../../atoms/loading-line";
import { GRECIAN_GREY } from "../../../../constants/colors";

const AmountModule = ({
  totalAmountDue,
  autoPayEnabled,
  isMobile,
  deactivatePaymentSchedule,
  navigateToSettings,
  autoPaySchedule,
  paymentPlanSchedule,
  isPaymentPlan,
  nextAutopayDate,
  description,
  subDescription,
  allowedPaymentInstruments,
  disableActions = false,
  isLoading = false
}) => {
  const [modalOpen, toggleModal] = useState(false);
  return (
    <Box padding="0 0.25rem 0 0">
      <Stack childGap="0">
        {isMobile && (
          <Text variant="pS" weight={FONT_WEIGHT_SEMIBOLD} textAlign="left">
            Amount Due
          </Text>
        )}
        {isLoading ? (
          <>
            <LoadingLine
              minWidth={"70"}
              maxWidth={"80"}
              height={"26px"}
              margin={isMobile ? "0" : "0 0 0 auto"}
              foregroundColor={GRECIAN_GREY}
              style={{ borderRadius: "0.125rem" }}
            />
            <LoadingLine
              minWidth={"134"}
              maxWidth={"178"}
              height={"21px"}
              margin={isMobile ? "0.25rem 0 0 0" : "0.25rem 0 0 auto"}
              foregroundColor={GRECIAN_GREY}
              style={{ borderRadius: "0.125rem" }}
            />
          </>
        ) : (
          <>
            <AmountCallout
              amount={displayCurrency(totalAmountDue)}
              textAlign={isMobile ? "left" : "right"}
            />
            {autoPayEnabled && (
              <AutopayModalModule
                autoPayActive={autoPayEnabled}
                autoPaySchedule={autoPaySchedule}
                toggleModal={toggleModal}
                modalOpen={modalOpen}
                navigateToSettings={navigateToSettings}
                deactivatePaymentSchedule={deactivatePaymentSchedule}
                isMobile={isMobile}
                paymentPlanSchedule={paymentPlanSchedule}
                isPaymentPlan={isPaymentPlan}
                nextAutopayDate={nextAutopayDate}
                controlType="link"
                description={description}
                subDescription={subDescription}
                allowedPaymentInstruments={allowedPaymentInstruments}
                disableActions={disableActions}
                action={disableActions ? noop : () => toggleModal(true)}
                onClick={
                  disableActions
                    ? noop
                    : () => {
                        toggleModal(true);
                      }
                }
                onKeyPress={
                  disableActions
                    ? noop
                    : e => {
                        e.key === "Enter" && toggleModal(true);
                      }
                }
              />
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default AmountModule;
