import React, { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence } from "framer-motion";
import {
  Motion,
  Box,
  ButtonWithAction,
  IconQuitLarge,
  constants,
  util
} from "@thecb/components";

/*
  An animated slider with backdrop. 
  
  Default position is at the body element of page (using React Portal). Alternatively, position can be set to viewport top/origin.
  
  Takes an isOpen prop to tell the slider when to open, a close function for the "X" button in the top right corner, isMobile, and then renders its children inside the slider. Children can be whatever elements you'd like. 

  Note: The slider itself doesn't have any background color, so it will look weird if you attempt to use it without passing in some children to render.

  If you need to change the content of the slider while keeping it open, see the SliderPanel component.

*/

/* 
  A slider panel can either provide its own close button within its header
  Or use this one, which floats outside of the panel on the top left
  This button is desktop only
*/

export const SliderPosition = {
  VIEWPORT_TOP: "viewport-top",
  DOCUMENT_TOP: "document-top"
};

export const SliderCloseButton = ({
  closeAction = util.noop,
  ariaLabel = "Close Modal"
}) => (
  <Box
    padding="0"
    extraStyles={`position: absolute; top: 16px; left: -64px; filter: drop-shadow(0px 1px 4px #292a33); > button { min-width: auto; margin: 0; }`}
  >
    <ButtonWithAction
      variant="smallGhost"
      contentOverride
      action={closeAction}
      dataQa="slider-close-btn"
      aria-label={ariaLabel}
    >
      <Box
        padding="0"
        background={constants.colors.WHITE}
        extraStyles={`display: flex; justify-content: center; align-items: center; height: 48px; width: 48px;`}
        borderRadius="50%"
        aria-hidden="true"
      >
        <IconQuitLarge />
      </Box>
    </ButtonWithAction>
  </Box>
);

const Slider = ({
  isOpen,
  isMobile,
  direction = "left",
  position = SliderPosition.DOCUMENT_TOP,
  triggerRef,
  children,
  closeSlider
}) => {
  const TOP_MOTION_Z_INDEX = 1500;
  const SECONDARY_MOTION_Z_INDEX = 75;
  const SLIDER_CONTENTS_Z_INDEX = 100;
  const sliderRef = useRef();
  const [hasSliderOpened, setHasSliderOpened] = useState(false);

  const handleKeyPress = event => {
    closeSlider && event.key === "Escape" ? closeSlider() : util.general.noop;
  };

  const setPageContentAriaHidden = () => {
    const root = document.querySelector("#root");
    root?.current?.setAttribute("aria-hidden", isOpen);
  };

  const getFirstFocusableElement = () => {
    const focusableElements = sliderRef?.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    return focusableElements?.length ? focusableElements[0] : null;
  };

  /*
    When the slider is open, focus is trapped within the slider. 
    Users can click the 'X' button or press the escape key to close the slider.
  */
  const outsideFocusHandler = event => {
    if (isOpen && !sliderRef?.current?.contains(event.target)) {
      event.stopPropagation();
      event.preventDefault();
      let firstFocusableElement = getFirstFocusableElement();
      firstFocusableElement?.focus();
    }
  };

  /* 
    Determines the initial focus element when the slider opens.
  */
  const setInitialFocus = () => {
    const focusElement = isOpen
      ? getFirstFocusableElement()
      : hasSliderOpened
      ? triggerRef?.current
      : null;
    focusElement?.focus();
  };

  const manageEventListeners = () => {
    if (isOpen) {
      window.addEventListener("focusin", outsideFocusHandler);
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("focusin", outsideFocusHandler);
      window.removeEventListener("keydown", handleKeyPress);
    }
  };

  useEffect(() => {
    if (!sliderRef?.current) return;
    setPageContentAriaHidden();
    setHasSliderOpened(isOpen);
    // slight delay to avoid jumpiness when setting the initial focus
    const timer = setTimeout(() => setInitialFocus(), 500);
    manageEventListeners();

    return () => {
      clearTimeout(timer);
      manageEventListeners();
    };
  }, [isOpen]);

  const variants = {
    open: {
      top: 0,
      bottom: 0,
      right: 0,
      transition: {
        delay: 0.22
      }
    },
    closed: {
      top: 0,
      bottom: 0,
      right: direction === "left" ? "-800px" : "10000px"
    }
  };
  const containerVariants = {
    open: {
      width: "100vw",
      height: "calc(100vh - 100px)",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0
    },
    closed: {
      width: "0",
      height: "0",
      transition: {
        delay: 0.22
      }
    }
  };

  const backdropVariants = {
    open: {
      opacity: 1,
      display: "block",
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    },
    closed: {
      opacity: 0,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transitionEnd: {
        display: "none"
      }
    }
  };

  const sliderContents = (
    <AnimatePresence>
      <Motion
        key="slider-container"
        variants={containerVariants}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        exit="closed"
        padding="0"
        extraStyles={`position: ${
          isMobile ? "absolute" : "fixed"
        }; z-index: ${TOP_MOTION_Z_INDEX}; ${
          isMobile && isOpen ? `min-height: -webkit-fill-available;}` : ``
        }`}
      >
        <Motion
          key="slider-backdrop"
          variants={backdropVariants}
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          exit="closed"
          padding="0"
          extraStyles={`width: 100vw; height: 100vh;`}
        />
        <Motion
          key="slider"
          id="slider"
          aria-modal="true"
          ref={sliderRef}
          variants={variants}
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          exit="closed"
          extraStyles={`position: ${
            isMobile ? "absolute" : "fixed"
          }; z-index: ${SECONDARY_MOTION_Z_INDEX}; width: 100%; max-width: 652px;`}
        >
          {children}
        </Motion>
      </Motion>
    </AnimatePresence>
  );

  if (position === SliderPosition.DOCUMENT_TOP) {
    return createPortal(
      <div aria-hidden={!isOpen} hidden={!isOpen}>
        {sliderContents}
      </div>,
      document.body
    );
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: SLIDER_CONTENTS_Z_INDEX
      }}
      aria-hidden={!isOpen}
      hidden={!isOpen}
    >
      {sliderContents}
    </div>
  );
};

export default Slider;
