import React from "react";
import { Box, Cluster, Stack } from "../../../components/atoms/layouts";
import {
  WHITE,
  BRIGHT_GREY,
  ATHENS_GREY,
  GRECIAN_GREY
} from "../../../constants/colors";
import { ACH_METHOD, CC_METHOD, CASH_METHOD } from "../../../constants/general";
import {
  IconModule,
  TitleModule,
  AmountModule,
  PaymentDetailsActions,
  InactiveControlsModule,
  InactiveTitleModule
} from "./modules";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./Obligation.theme";

const Obligation = ({
  config,
  obligations,
  actions,
  autoPayEnabled,
  autoPayAvailable,
  handleAutopayAction,
  deactivatePaymentSchedule,
  autoPaySchedule,
  paymentPlanSchedule,
  navigateToSettings,
  isMobile,
  isPaymentPlan,
  nextAutopayDate,
  obligationAssocID,
  dueDate,
  agencyName,
  inactive = false,
  inactiveLookupTitle = "",
  inactiveLookupInput = "Account",
  inactiveLookupValue = "",
  isInCustomerManagement = false,
  themeValues,
  cartEnabled = false,
  cartConfig = undefined,
  multiCartParams = undefined,
  isInCart = false,
  isLoading = false,
  showRemoveButton = true
}) => {
  /*
    The value of obligations is always an array. It can contain:
      - A single obligation
      - A group of obligations (a "collection")
    
    The top level desc/subdesc for all obligations in a collection is the same
    (Collection accounts look different in the Account Details page)
  */
  const firstObligation = obligations?.[0] ?? {};
  const {
    allowedPaymentInstruments = [CASH_METHOD, CC_METHOD, ACH_METHOD],
    description = "Account",
    subDescription = obligationAssocID
  } = firstObligation;
  const customAttributes = firstObligation?.customAttributes ?? {};
  const boxShadowValue =
    "0px 2px 4px rgba(41, 42, 51, 0.1), 0px 1px 1px 2px rgba(41, 42, 51, 0.1);";

  const loadingObligation = (
    <Box
      padding="0"
      borderRadius="4px"
      boxShadow={boxShadowValue}
      as="section"
      aria-label={`${description} - ${subDescription}`}
      border={`1px solid ${GRECIAN_GREY}`}
      borderWidthOverride="1px 0 0 0"
    >
      <Box background={WHITE} padding={isMobile ? "16px" : "24px 16px"}>
        <Stack childGap="24px">
          <Box
            key={`${firstObligation?.id}-top`}
            padding="0 8px"
            minWidth="100%"
          >
            <Cluster
              justify="space-between"
              align="center"
              childGap="4px"
              nowrap
            >
              <Box padding="0">
                <Cluster justify="flex-start" align="center">
                  {!isMobile && (
                    <IconModule
                      icon={config.icon}
                      iconDefault={config.iconDefault}
                      configIconMap={config.iconMap}
                      iconValue={config.iconValue}
                      customAttributes={customAttributes}
                      iconColor={themeValues.iconColor}
                    />
                  )}
                  <TitleModule
                    title={description}
                    subtitle={subDescription}
                    titleColor={BRIGHT_GREY}
                    isMobile={isMobile}
                  />
                </Cluster>
              </Box>
              {!isMobile && (
                <AmountModule
                  isMobile={isMobile}
                  disableActions={true}
                  isLoading={true}
                />
              )}
            </Cluster>
          </Box>
          {!isMobile && (
            <PaymentDetailsActions
              obligations={obligations}
              autoPayEnabled={autoPayEnabled}
              autoPayAvailable={autoPayAvailable}
              handleAutopayAction={handleAutopayAction}
              deactivatePaymentSchedule={deactivatePaymentSchedule}
              autoPaySchedule={autoPaySchedule}
              paymentPlanSchedule={paymentPlanSchedule}
              navigateToSettings={navigateToSettings}
              config={config}
              actions={actions}
              isMobile={isMobile}
              description={description}
              subDescription={subDescription}
              disableActions={true}
              cartEnabled={cartEnabled}
              cartConfig={cartConfig}
              multiCartParams={multiCartParams}
              isInCart={isInCart}
              isContentLoading={true}
            />
          )}
        </Stack>
      </Box>
      {isMobile && (
        <PaymentDetailsActions
          obligations={obligations}
          autoPayEnabled={autoPayEnabled}
          autoPayAvailable={autoPayAvailable}
          handleAutopayAction={handleAutopayAction}
          deactivatePaymentSchedule={deactivatePaymentSchedule}
          autoPaySchedule={autoPaySchedule}
          paymentPlanSchedule={paymentPlanSchedule}
          navigateToSettings={navigateToSettings}
          config={config}
          actions={actions}
          isMobile={isMobile}
          description={description}
          subDescription={subDescription}
          disableActions={true}
          cartEnabled={cartEnabled}
          cartConfig={cartConfig}
          multiCartParams={multiCartParams}
          isInCart={isInCart}
          isContentLoading={true}
        />
      )}
    </Box>
  );

  const activeObligation = (
    <Box
      padding="0"
      borderRadius="4px"
      boxShadow={boxShadowValue}
      as="section"
      aria-label={`${description} - ${subDescription}`}
      border={`1px solid ${GRECIAN_GREY}`}
      borderWidthOverride="1px 0 0 0"
    >
      <Box background={WHITE} padding={isMobile ? "16px" : "24px 16px"}>
        <Stack childGap="24px">
          <Box
            key={`${firstObligation?.id}-top`}
            padding="0 8px"
            minWidth="100%"
          >
            <Cluster
              justify="space-between"
              align="center"
              childGap="4px"
              nowrap
            >
              <Box padding="0">
                <Cluster justify="flex-start" align="center">
                  {!isMobile && (
                    <IconModule
                      icon={config.icon}
                      iconDefault={config.iconDefault}
                      configIconMap={config.iconMap}
                      iconValue={config.iconValue}
                      customAttributes={customAttributes}
                      iconColor={themeValues.iconColor}
                    />
                  )}
                  <TitleModule
                    title={description}
                    subtitle={subDescription}
                    titleColor={BRIGHT_GREY}
                    isMobile={isMobile}
                  />
                </Cluster>
              </Box>
              {!isMobile && (
                <AmountModule
                  totalAmountDue={obligations?.reduce(
                    (acc, curr) => acc + curr.amountDue,
                    0
                  )}
                  autoPayEnabled={autoPayEnabled}
                  isMobile={isMobile}
                  deactivatePaymentSchedule={deactivatePaymentSchedule}
                  navigateToSettings={navigateToSettings}
                  autoPaySchedule={autoPaySchedule}
                  paymentPlanSchedule={paymentPlanSchedule}
                  isPaymentPlan={isPaymentPlan}
                  nextAutopayDate={nextAutopayDate}
                  description={description}
                  subDescription={subDescription}
                  allowedPaymentInstruments={allowedPaymentInstruments}
                  disableActions={isInCustomerManagement}
                />
              )}
            </Cluster>
          </Box>
          {!isMobile && (
            <PaymentDetailsActions
              obligations={obligations}
              autoPayEnabled={autoPayEnabled}
              autoPayAvailable={autoPayAvailable}
              handleAutopayAction={handleAutopayAction}
              deactivatePaymentSchedule={deactivatePaymentSchedule}
              autoPaySchedule={autoPaySchedule}
              paymentPlanSchedule={paymentPlanSchedule}
              navigateToSettings={navigateToSettings}
              config={config}
              actions={actions}
              isMobile={isMobile}
              isPaymentPlan={isPaymentPlan}
              nextAutopayDate={nextAutopayDate}
              obligationAssocID={obligationAssocID}
              description={description}
              subDescription={subDescription}
              allowedPaymentInstruments={allowedPaymentInstruments}
              disableActions={isInCustomerManagement}
              cartEnabled={cartEnabled}
              cartConfig={cartConfig}
              multiCartParams={multiCartParams}
              isInCart={isInCart}
            />
          )}
        </Stack>
      </Box>
      {isMobile && (
        <PaymentDetailsActions
          obligations={obligations}
          autoPayEnabled={autoPayEnabled}
          autoPayAvailable={autoPayAvailable}
          handleAutopayAction={handleAutopayAction}
          deactivatePaymentSchedule={deactivatePaymentSchedule}
          autoPaySchedule={autoPaySchedule}
          paymentPlanSchedule={paymentPlanSchedule}
          navigateToSettings={navigateToSettings}
          config={config}
          actions={actions}
          isMobile={isMobile}
          isPaymentPlan={isPaymentPlan}
          nextAutopayDate={nextAutopayDate}
          obligationAssocID={obligationAssocID}
          description={description}
          subDescription={subDescription}
          allowedPaymentInstruments={allowedPaymentInstruments}
          disableActions={isInCustomerManagement}
          cartEnabled={cartEnabled}
          cartConfig={cartConfig}
          multiCartParams={multiCartParams}
          isInCart={isInCart}
        />
      )}
    </Box>
  );

  const inactiveObligation = (
    <Box
      padding="0"
      borderRadius="4px"
      boxShadow={boxShadowValue}
      as="section"
      aria-label={`${inactiveLookupTitle} ${inactiveLookupInput}: ${inactiveLookupValue}`}
    >
      <Box background={ATHENS_GREY} padding={isMobile ? "16px" : "24px"}>
        <Stack childGap="14px">
          <Box key={`${obligationAssocID}-top`} padding="0" minWidth="100%">
            <Cluster
              justify="space-between"
              align="center"
              childGap="40px"
              nowrap
            >
              <Box padding="0">
                <Cluster justify="flex-start" align="center" nowrap>
                  <IconModule
                    icon={config.icon}
                    iconDefault={config.iconDefault}
                    configIconMap={config.iconMap}
                    iconValue={config.iconValue}
                    customAttributes={customAttributes}
                    iconColor={themeValues.iconColor}
                  />
                  <InactiveTitleModule
                    title={inactiveLookupTitle}
                    subtitle={`${inactiveLookupInput}: ${inactiveLookupValue}`}
                    titleColor={BRIGHT_GREY}
                    autoPayEnabled={autoPayEnabled}
                    isMobile={isMobile}
                  />
                </Cluster>
              </Box>
              {!isMobile && (
                <InactiveControlsModule
                  obligations={obligations}
                  autoPayEnabled={autoPayEnabled}
                  deactivatePaymentSchedule={deactivatePaymentSchedule}
                  autoPaySchedule={autoPaySchedule}
                  paymentPlanSchedule={paymentPlanSchedule}
                  config={config}
                  actions={actions}
                  isMobile={isMobile}
                  nextAutopayDate={nextAutopayDate}
                  obligationAssocID={obligationAssocID}
                  dueDate={dueDate}
                  agencyName={agencyName}
                  configType={config.type}
                  description={description}
                  subDescription={subDescription}
                  allowedPaymentInstruments={allowedPaymentInstruments}
                  disableActions={isInCustomerManagement}
                  showRemoveButton={showRemoveButton}
                />
              )}
            </Cluster>
          </Box>
          {isMobile && (
            <InactiveControlsModule
              obligations={obligations}
              autoPayEnabled={autoPayEnabled}
              deactivatePaymentSchedule={deactivatePaymentSchedule}
              autoPaySchedule={autoPaySchedule}
              paymentPlanSchedule={paymentPlanSchedule}
              config={config}
              actions={actions}
              isMobile={isMobile}
              nextAutopayDate={nextAutopayDate}
              obligationAssocID={obligationAssocID}
              dueDate={dueDate}
              agencyName={agencyName}
              configType={config.type}
              description={description}
              subDescription={subDescription}
              allowedPaymentInstruments={allowedPaymentInstruments}
              disableActions={isInCustomerManagement}
              showRemoveButton={showRemoveButton}
            />
          )}
        </Stack>
      </Box>
    </Box>
  );

  return isLoading
    ? loadingObligation
    : inactive
    ? inactiveObligation
    : activeObligation;
};

export default themeComponent(Obligation, "Obligation", fallbackValues);
